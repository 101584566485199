import clsx from "clsx";
import { useEffect } from "react";
import useBreakpoint from "use-breakpoint";
import { useAppStore } from "../../../../hooks/useAppStore";
import type { NavigationItem } from "../../types";
import NavigationMenuItem from "../NavigationMenuItem/NavigationMenuItem";
import "./style.scss";

type Props = {
  data: NavigationItem[];
};

const NavigationMobile = ({ data }: Props) => {
  const isOpen = useAppStore((s) => s.mobileNavOpen);
  const setAppState = useAppStore((s) => s.setState);
  const { breakpoint } = useBreakpoint({ mobile: 0, desktop: 992 });

  useEffect(() => {
    const element = document.getElementById("top-navigation");
    if (element) {
      if (isOpen) {
        element.classList.add("open");
      } else {
        element.classList.remove("open");
      }
    }
  }, [isOpen]);

  // auto close mobile nav state
  useEffect(() => {
    if (breakpoint === "desktop" && isOpen) {
      setAppState({ mobileNavOpen: false });
    }
  }, [breakpoint]);

  return (
    <div
      id="mobile-nav"
      className={clsx({
        open: isOpen,
      })}
    >
      <div>
        <div id="mobile-nav-content">
          <ul>
            {data.map((item, index) => (
              <NavigationMenuItem
                data={item}
                key={index}
                floating={false}
                onClick={() => {
                  setAppState({ mobileNavOpen: false });
                }}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationMobile;
